import { combineReducers } from "redux";
import { LoginUserReducers } from "../Reducers/LoginReducers";
import {
  AddProductionProblemReducers,
  AddRequirementReducers,
  DeleteRequirementReducers,
  GetOperationNodesReducers,
  GetProblemListReducers,
  GetRequirementListReducers,
  ManufacturingPackingDropdownDataReducers,
  getInventoryDetailsReducers,
  getProblemTypeReducers,
  getTaskListReducers,
} from "../Reducers/ProductionReducers";

const rootReducer = combineReducers({
  login: LoginUserReducers,
  problems: getProblemTypeReducers,
  operations: GetOperationNodesReducers,
  addProductionProblem: AddProductionProblemReducers,
  problemList: GetProblemListReducers,
  taskList: getTaskListReducers,
  addRequirements: AddRequirementReducers,
  requirements: GetRequirementListReducers,
  deleteReq: DeleteRequirementReducers,
  manufacturingDropdown: ManufacturingPackingDropdownDataReducers,
  InventoryShow: getInventoryDetailsReducers,
});

export default rootReducer;
