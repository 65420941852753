import { CLEAR_ERRORS } from "../Constants/LoginConstants";
import {
  ADD_PRODUCTION_PROBLEM_FAILURE,
  ADD_PRODUCTION_PROBLEM_REQUEST,
  ADD_PRODUCTION_PROBLEM_SUCCESS,
  ADD_REQUIREMENT_FAILURE,
  ADD_REQUIREMENT_REQUEST,
  ADD_REQUIREMENT_SUCCESS,
  DELETE_REQUIREMENT_FAILURE,
  DELETE_REQUIREMENT_REQUEST,
  DELETE_REQUIREMENT_SUCCESS,
  GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE,
  GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST,
  GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS,
  GET_INVENTORY_DETAILS_FAILURE,
  GET_INVENTORY_DETAILS_REQUEST,
  GET_INVENTORY_DETAILS_SUCCESS,
  GET_INVOICE_LIST_FAILURE,
  GET_INVOICE_LIST_REQUEST,
  GET_INVOICE_LIST_SUCCESS,
  GET_OPERATION_NODES_FAILURE,
  GET_OPERATION_NODES_REQUEST,
  GET_OPERATION_NODES_SUCCESS,
  GET_PROBLEM_LIST_FAILURE,
  GET_PROBLEM_LIST_REQUEST,
  GET_PROBLEM_LIST_SUCCESS,
  GET_PROBLEM_TYPES_FAILURE,
  GET_PROBLEM_TYPES_REQUEST,
  GET_PROBLEM_TYPES_SUCCESS,
  GET_REQUIREMENT_LIST_FAILURE,
  GET_REQUIREMENT_LIST_REQUEST,
  GET_REQUIREMENT_LIST_SUCCESS,
  GET_TASK_LIST_FAILURE,
  GET_TASK_LIST_REQUEST,
  GET_TASK_LIST_SUCCESS,
  GET_TO_LOCATION_DROPDOWN_LIST_FAILURE,
  GET_TO_LOCATION_DROPDOWN_LIST_REQUEST,
  GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS,
} from "../Constants/ProductionConstants";

// Get Problem Type Reducers
export const getProblemTypeReducers = (
  state = { problem_type: {} },
  action
) => {
  switch (action.type) {
    case GET_PROBLEM_TYPES_REQUEST:
      return {
        loading: true,
      };
    case GET_PROBLEM_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        problem_type: action.payload,
      };
    case GET_PROBLEM_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Operation Nodes Reducers
export const GetOperationNodesReducers = (
  state = { operation_nodes: {} },
  action
) => {
  switch (action.type) {
    case GET_OPERATION_NODES_REQUEST:
      return {
        loading: true,
      };
    case GET_OPERATION_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
        operation_nodes: action.payload,
      };
    case GET_OPERATION_NODES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Production Problem Reducers
export const AddProductionProblemReducers = (
  state = { production_problem: {} },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCTION_PROBLEM_REQUEST:
      return {
        loading: true,
      };
    case ADD_PRODUCTION_PROBLEM_SUCCESS:
      return {
        ...state,
        loading: false,
        production_problem: action.payload,
      };
    case ADD_PRODUCTION_PROBLEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Problem Data Reducers
export const GetProblemListReducers = (
  state = { problem_history: {} },
  action
) => {
  switch (action.type) {
    case GET_PROBLEM_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_PROBLEM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        problem_history: action.payload,
      };
    case GET_PROBLEM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Requirement Reducers
export const AddRequirementReducers = (
  state = { add_requirement: {} },
  action
) => {
  switch (action.type) {
    case ADD_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case ADD_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        add_requirement: action.payload,
      };
    case ADD_REQUIREMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Requirement Reducers
export const getTaskListReducers = (state = { task_list: {} }, action) => {
  switch (action.type) {
    case GET_TASK_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        task_list: action.payload,
      };
    case GET_TASK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Requirement List Reducers
export const GetRequirementListReducers = (
  state = { requirement_list: {} },
  action
) => {
  switch (action.type) {
    case GET_REQUIREMENT_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_REQUIREMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        requirement_list: action.payload,
      };
    case GET_REQUIREMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Delete Requirement Reducers
export const DeleteRequirementReducers = (
  state = { delete_requirement: {} },
  action
) => {
  switch (action.type) {
    case DELETE_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case DELETE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        delete_requirement: action.payload,
      };
    case DELETE_REQUIREMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Manufacturing Dropdown Data List Reducers
export const ManufacturingPackingDropdownDataReducers = (
  state = { from_location: {}, to_location: {}, invoice_list: {} },
  action
) => {
  switch (action.type) {
    case GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST:
    case GET_TO_LOCATION_DROPDOWN_LIST_REQUEST:
    case GET_INVOICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        from_location: action.payload,
      };
    case GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        to_location: action.payload,
      };
    case GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_list: action.payload,
      };
    case GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE:
    case GET_TO_LOCATION_DROPDOWN_LIST_FAILURE:
    case GET_INVOICE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Inventory Details Reducers
export const getInventoryDetailsReducers = (
  state = { inventory_details: {} },
  action
) => {
  switch (action.type) {
    case GET_INVENTORY_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_INVENTORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory_details: action.payload,
      };
    case GET_INVENTORY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
