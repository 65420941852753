// Problem Type List For Dropdown Constants
export const GET_PROBLEM_TYPES_REQUEST = "GET_PROBLEM_TYPES_REQUEST";
export const GET_PROBLEM_TYPES_SUCCESS = "GET_PROBLEM_TYPES_SUCCESS";
export const GET_PROBLEM_TYPES_FAILURE = "GET_PROBLEM_TYPES_FAILURE";

// Operation Node List For Dropdown Constants
export const GET_OPERATION_NODES_REQUEST = "GET_OPERATION_NODES_REQUEST";
export const GET_OPERATION_NODES_SUCCESS = "GET_OPERATION_NODES_SUCCESS";
export const GET_OPERATION_NODES_FAILURE = "GET_OPERATION_NODES_FAILURE";

// Add Production Problem Constants
export const ADD_PRODUCTION_PROBLEM_REQUEST = "ADD_PRODUCTION_PROBLEM_REQUEST";
export const ADD_PRODUCTION_PROBLEM_SUCCESS = "ADD_PRODUCTION_PROBLEM_SUCCESS";
export const ADD_PRODUCTION_PROBLEM_FAILURE = "ADD_PRODUCTION_PROBLEM_FAILURE";

// Problem List Constants
export const GET_PROBLEM_LIST_REQUEST = "GET_PROBLEM_LIST_REQUEST";
export const GET_PROBLEM_LIST_SUCCESS = "GET_PROBLEM_LIST_SUCCESS";
export const GET_PROBLEM_LIST_FAILURE = "GET_PROBLEM_LIST_FAILURE";

// Add Requirement Constants
export const ADD_REQUIREMENT_REQUEST = "ADD_REQUIREMENT_REQUEST";
export const ADD_REQUIREMENT_SUCCESS = "ADD_REQUIREMENT_SUCCESS";
export const ADD_REQUIREMENT_FAILURE = "ADD_REQUIREMENT_FAILURE";

// Add Requirement Constants
export const GET_TASK_LIST_REQUEST = "GET_TASK_LIST_REQUEST";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_FAILURE = "GET_TASK_LIST_FAILURE";

// Get Requirement List Constants
export const GET_REQUIREMENT_LIST_REQUEST = "GET_REQUIREMENT_LIST_REQUEST";
export const GET_REQUIREMENT_LIST_SUCCESS = "GET_REQUIREMENT_LIST_SUCCESS";
export const GET_REQUIREMENT_LIST_FAILURE = "GET_REQUIREMENT_LIST_FAILURE";

// Delete Requirement Constants
export const DELETE_REQUIREMENT_REQUEST = "DELETE_REQUIREMENT_REQUEST";
export const DELETE_REQUIREMENT_SUCCESS = "DELETE_REQUIREMENT_SUCCESS";
export const DELETE_REQUIREMENT_FAILURE = "DELETE_REQUIREMENT_FAILURE";

// Manufacturing Dropdown Constants
export const GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST =
  "GET_FROM_LOCATION_DROPDOWN_LIST_REQUEST";
export const GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS =
  "GET_FROM_LOCATION_DROPDOWN_LIST_SUCCESS";
export const GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE =
  "GET_FROM_LOCATION_DROPDOWN_LIST_FAILURE";

export const GET_TO_LOCATION_DROPDOWN_LIST_REQUEST =
  "GET_TO_LOCATION_DROPDOWN_LIST_REQUEST";
export const GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS =
  "GET_TO_LOCATION_DROPDOWN_LIST_SUCCESS";
export const GET_TO_LOCATION_DROPDOWN_LIST_FAILURE =
  "GET_TO_LOCATION_DROPDOWN_LIST_FAILURE";

export const GET_INVOICE_LIST_REQUEST = "GET_INVOICE_LIST_REQUEST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILURE = "GET_INVOICE_LIST_FAILURE";

// Get Inventory Details
export const GET_INVENTORY_DETAILS_REQUEST = "GET_INVENTORY_DETAILS_REQUEST";
export const GET_INVENTORY_DETAILS_SUCCESS = "GET_INVENTORY_DETAILS_SUCCESS";
export const GET_INVENTORY_DETAILS_FAILURE = "GET_INVENTORY_DETAILS_FAILURE";
